import React, { useEffect, useState } from 'react';
import {
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, TextField, Typography, IconButton, Modal, FormControl, Button, 
  Input, CircularProgress, Pagination
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingBlog, setEditingBlog] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [image, setImage] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/blog`, {
          headers: { 'x-auth-token': token }
        });
        setBlogs(response.data);
        setFilteredBlogs(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch blogs');
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const handleDelete = async (id) => {
    try {
      const token = sessionStorage.getItem('admin_token');
      await axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/blog/${id}`, {
        headers: { 'x-auth-token': token }
      });
      setBlogs(blogs.filter(blog => blog._id !== id));
      setFilteredBlogs(filteredBlogs.filter(blog => blog._id !== id));
    } catch (err) {
      setError('Failed to delete blog');
    }
  };

  const handleEditClick = (blog) => {
    setEditingBlog(blog);
    setFormValues({ ...blog });
    setImage(blog.photo);
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditingBlog(null);
    setFormValues({});
    setImage(null);
  };

  const handleFormChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem('admin_token');
      await axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/blog/${editingBlog._id}`, {
        ...formValues,
        photo: image || editingBlog.photo,
      }, {
        headers: { 'x-auth-token': token }
      });
      const updatedBlog = { ...editingBlog, ...formValues, photo: image || editingBlog.photo };
      setBlogs(blogs.map(blog =>
        blog._id === editingBlog._id ? updatedBlog : blog
      ));
      setFilteredBlogs(filteredBlogs.map(blog =>
        blog._id === editingBlog._id ? updatedBlog : blog
      ));
      handleCancelEdit();
    } catch (err) {
      setError('Failed to update blog');
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    if (query) {
      setFilteredBlogs(blogs.filter(blog =>
        blog.title.toLowerCase().includes(query) || blog.description.toLowerCase().includes(query)
      ));
    } else {
      setFilteredBlogs(blogs);
    }
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Calculate the current blogs to display based on pagination
  const indexOfLastBlog = currentPage * itemsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - itemsPerPage;
  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1">Blogs</Typography>
        <TextField 
          label="Search" 
          variant="outlined" 
          sx={{ width: '300px' }} 
          value={searchQuery}
          onChange={handleSearch}
        />
      </Box>

      {/* Edit Form Modal */}
      <Modal
        open={isEditing}
        onClose={handleCancelEdit}
        aria-labelledby="edit-blog-title"
        aria-describedby="edit-blog-description"
      >
        <Box
          sx={{
            p: 3,
            bgcolor: 'background.paper',
            mx: 'auto',
            mt: 5,
            maxWidth: '600px',
            borderRadius: 1,
            overflow: 'auto',
            maxHeight: '80vh'
          }}
        >
          <Typography id="edit-blog-title" variant="h6" component="h2">
            Edit Blog
          </Typography>
          <form onSubmit={handleFormSubmit}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Title"
                name="title"
                value={formValues.title || ''}
                onChange={handleFormChange}
                required
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Description"
                name="description"
                value={formValues.description || ''}
                onChange={handleFormChange}
                multiline
                rows={4}
                required
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <Input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                sx={{ mb: 2 }}
              />
              {image && <img src={image} alt="Preview" style={{ maxWidth: '100px' }} />}
            </FormControl>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button variant="contained" type="submit">Update</Button>
              <Button variant="outlined" onClick={handleCancelEdit}>Cancel</Button>
            </Box>
          </form>
        </Box>
      </Modal>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="blogs table">
          <TableHead sx={{ backgroundColor: '#478ccf' }}>
            <TableRow>
              <TableCell sx={{ color: 'white' }}>#</TableCell>
              <TableCell sx={{ color: 'white' }}>Title</TableCell>
              <TableCell sx={{ color: 'white' }}>Description</TableCell>
              <TableCell sx={{ color: 'white' }}>Photo</TableCell>
              <TableCell sx={{ color: 'white' }}>Date</TableCell>
              <TableCell align="center" sx={{ color: 'white' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentBlogs.map((blog, index) => (
              <TableRow
                key={blog._id}
                sx={{
                  '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                  '&:hover': { backgroundColor: '#f1f1f1' },
                }}
              >
                <TableCell>{index + 1 + indexOfFirstBlog}</TableCell>
                <TableCell>{blog.title}</TableCell>
                <TableCell>{blog.description.length > 100 ? `${blog.description.substring(0, 100)}...` : blog.description}</TableCell>
                <TableCell>
                  <img src={blog.photo} alt={blog.title} style={{ width: '100px', height: 'auto' }} />
                </TableCell>
                <TableCell>{new Date(blog.date).toLocaleDateString()}</TableCell>
                <TableCell align="center">
                  <IconButton aria-label="edit" onClick={() => handleEditClick(blog)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton aria-label="delete" sx={{ color: 'red' }} onClick={() => handleDelete(blog._id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Pagination
          count={Math.ceil(filteredBlogs.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default Blogs;
