import React, { useState, useEffect } from 'react';
import BlogCard from '../components/BlogCard';

const BlogPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_BACKEND_BASE_URL + '/blog');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setBlogs(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div style={{
      padding: '20px',
      margin: '0 auto',
      width: '100%',
      maxWidth: '1200px',
      boxSizing: 'border-box',
      overflowX: 'hidden', // Prevent horizontal scroll
    }}>
      {blogs.length === 0 ? (
        <div>No blogs available</div>
      ) : (
        blogs.map((blog, index) => (
          <BlogCard 
            key={index}
            date={blog.date}
            title={blog.title}
            description={blog.description}
            image={blog.photo || ''}  // Handle optional image
          />
        ))
      )}
    </div>
  );
};

export default BlogPage;
