import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const StyledCard = styled(Card)(({ theme }) => ({
  textAlign: "center",
  borderRadius: "10px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
  transition: "transform 0.3s",
  position: "relative",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.05)",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "#4535c1",
      opacity: 0.8,
      zIndex: 0,
      borderRadius: "10px",
    },
    "& .cardContent": {
      opacity: 1,
      visibility: "visible",
      zIndex: 1,
    },
    "& .logoAndName": {
      opacity: 0,
      visibility: "hidden",
    },
  },
}));

const CardOverlay = styled(CardContent)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  opacity: 0,
  visibility: "hidden",
  color: "#ffffff",
  zIndex: 1,
  transition: "opacity 0.3s, visibility 0.3s",
}));

const LogoAndNameWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 2,
}));

const Categories = () => {
  const [stores, setStores] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL+"/company");
        setStores(response.data);
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    };

    fetchStores();
  }, []);

  const handleCardClick = (storeName) => {
    navigate(`/companies-offer/${storeName}`);
  };

  return (
    <Box sx={{ p: 2, maxWidth: 1200, margin: "auto" }}>
      <Typography variant="h5" gutterBottom>
        Top Stores
      </Typography>
      <Grid container spacing={2}>
        {stores.slice(0, 11).map((store, index) => (
          <Grid item xs={6} sm={6} md={4} lg={2} key={index}>
            <StyledCard onClick={() => handleCardClick(store.name)}>
              <LogoAndNameWrapper className="logoAndName">
                <Box
                  component="img"
                  src={store.logo}
                  alt={store.name}
                  sx={{
                    width: 100,
                    height: 100,
                    objectFit: "contain",
                    borderRadius: 1,
                    display: "block",
                    mx: "auto",
                  }}
                />
                <CardContent>
                  <Typography variant="h6">{store.name}</Typography>
                </CardContent>
              </LogoAndNameWrapper>
              <CardOverlay className="cardContent">
                <Typography variant="body2">
                  {store.products} Products
                </Typography>
              </CardOverlay>
            </StyledCard>
          </Grid>
        ))}
        {stores.length > 11 && (
          <Grid item xs={12} sm={12} md={4} lg={2}>
            <Button variant="outlined" sx={{ height: "100%", width: "100%" }}>
              View All
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Categories;
