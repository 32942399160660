// AdminLogin.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import { Box, Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios'; // Import Axios for HTTP requests
import Illustration from '../assets/login_page.png'; // Use the same illustration for consistency

const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL+'/auth';

const AdminLoginPageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const IllustrationContainer = styled(Box)(({ theme }) => ({
  width: '60%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const IllustrationImage = styled('img')({
  maxWidth: '100%',
  height: 'auto',
});

const AdminFormContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: theme.shape?.borderRadius || 4,
  boxShadow: theme.shadows?.[3] || '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  padding: theme.spacing(4),
  border: '1px solid #4535c1',
  width: '80%',
  maxWidth: '400px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: theme.spacing(2),
  },
}));

const AdminLogin = () => {
  const [formData, setFormData] = useState({
    adminEmail: '',
    adminPassword: '',
  });
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMsg(''); // Clear previous error messages
    try {
      const response = await axios({
        method: 'post',
        baseURL: backendBaseUrl,
        url: '/admin-login',
        data: {
          email: formData.adminEmail,
          password: formData.adminPassword,
        }
      });

      if (response.data.token) {
        sessionStorage.setItem('admin_token', response.data.token);
        navigate('/admin/dashboard');
      } else {
        setMsg(response.data.msg || 'Unknown error occurred');
      }
    } catch (error) {
      setMsg(error.response?.data?.msg || 'An error occurred. Please try again.');
      console.error('Error:', error); // Handle error gracefully
    }
  };

  return (
    <AdminLoginPageContainer>
      <IllustrationContainer>
        <IllustrationImage src={Illustration} alt="Illustration" />
      </IllustrationContainer>
      <AdminFormContainer component="form" onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom sx={{ color: '#4535c1' }}>Admin Login</Typography>
        <TextField
          fullWidth
          margin="normal"
          label="Admin Email"
          variant="outlined"
          name="adminEmail"
          value={formData.adminEmail}
          onChange={handleChange}
          sx={{
            marginBottom: 2,
            height: { xs: 48, sm: 56 }, // Adjust height for different screen sizes
          }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Admin Password"
          variant="outlined"
          type="password"
          name="adminPassword"
          value={formData.adminPassword}
          onChange={handleChange}
          sx={{
            marginBottom: 2,
            height: { xs: 48, sm: 56 }, // Adjust height for different screen sizes
          }}
        />
        <Button type="submit" fullWidth variant="contained" sx={{ backgroundColor: '#4535c1', color: '#fff' }}>Admin Login</Button>
        {msg && <Typography color="error" variant="body2">{msg}</Typography>}
      </AdminFormContainer>
    </AdminLoginPageContainer>
  );
};

export default AdminLogin;
