import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, IconButton, Button, Modal, FormControl, CircularProgress, InputLabel, Select, MenuItem, Input } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import Pagination from '@mui/material/Pagination';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingProduct, setEditingProduct] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [image, setImage] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [productsPerPage] = useState(10);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL+'/product');
        const externalProducts = response.data.filter(product => product.productType === 'external');
        setProducts(externalProducts);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch products');
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleDelete = async (id) => {
    try {
      const token = sessionStorage.getItem('admin_token'); 
      await axios.delete(process.env.REACT_APP_BACKEND_BASE_URL+`/product/${id}`, {
        headers: { 'x-auth-token': token }
      });
      setProducts(products.filter(product => product._id !== id));
    } catch (err) {
      setError('Failed to delete product');
    }
  };

  const handleEditClick = (product) => {
    setEditingProduct(product);
    // console.log(product.photo)
    setFormValues({
      ...product,
      startDate: formatDateForInput(product.startDate),
      endDate: formatDateForInput(product.endDate),
    });
    setImage(null); // Reset image to avoid displaying old image when editing
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditingProduct(null);
    setFormValues({});
    setImage(null);
  };

  const handleFormChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file); // Set the file object directly
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const token = sessionStorage.getItem('admin_token');
    const formData = new FormData();

    // Append form values to formData
    formData.append('name', formValues.name || '');
    formData.append('description', formValues.description || '');
    formData.append('link', formValues.link || '');
    formData.append('company', formValues.company || '');
    formData.append('startDate', formValues.startDate ? new Date(formValues.startDate).toISOString() : '');
    formData.append('endDate', formValues.endDate ? new Date(formValues.endDate).toISOString() : '');
    formData.append('couponCode', formValues.couponCode || '');
    formData.append('productType', 'external');

    // Append image file if available
    if (image) {
      formData.append('photo', image);
    }

    try {
      // Send PUT request with formData
      await axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/product/${editingProduct._id}`, formData, {
        headers: { 
          'x-auth-token': token,
          'Content-Type': 'multipart/form-data'
        }
      });

      // Update products state with new data
      setProducts(products.map(product => 
        product._id === editingProduct._id ? { ...product, ...formValues, startDate: formValues.startDate ? new Date(formValues.startDate).toISOString() : '', endDate: formValues.endDate ? new Date(formValues.endDate).toISOString() : '', photo: image ? URL.createObjectURL(image) : product.photo } : product
      ));

      handleCancelEdit();
    } catch (err) {
      console.error('Failed to update product:', err.response ? err.response.data : err.message);
      setError(`Failed to update product: ${err.response ? err.response.data.message : err.message}`);
    }
  };

  const formatDateForInput = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const formatDateDisplay = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    product.company.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const paginatedProducts = filteredProducts.slice((page - 1) * productsPerPage, page * productsPerPage);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1">Products</Typography>
        <TextField 
          label="Search" 
          variant="outlined" 
          sx={{ width: '300px' }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>

      {/* Edit Form Modal */}
      <Modal
        open={isEditing}
        onClose={handleCancelEdit}
        aria-labelledby="edit-product-title"
        aria-describedby="edit-product-description"
      >
        <Box 
          sx={{ 
            p: 3, 
            bgcolor: 'background.paper', 
            mx: 'auto', 
            mt: 5, 
            maxWidth: '600px', 
            borderRadius: 1,
            overflow: 'auto', // Make the modal scrollable if content overflows
            maxHeight: '80vh' // Limit the height of the modal
          }}
        >
          <Typography id="edit-product-title" variant="h6" component="h2">
            Edit Product
          </Typography>
          <form onSubmit={handleFormSubmit}>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="productType" shrink>Product Type</InputLabel>
              <Select
                name="productType"
                value="external" // Fixed to external
                onChange={handleFormChange}
                inputProps={{ readOnly: true }}
                required
              >
                <MenuItem value="external">External</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Name"
                name="name"
                value={formValues.name || ''}
                onChange={handleFormChange}
                required
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Description"
                name="description"
                value={formValues.description || ''}
                onChange={handleFormChange}
                required
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Link"
                name="link"
                value={formValues.link || ''}
                onChange={handleFormChange}
                required
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Company"
                name="company"
                value={formValues.company || ''}
                onChange={handleFormChange}
                required
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Start Date"
                name="startDate"
                type="date"
                value={formValues.startDate || ''}
                onChange={handleFormChange}
                InputLabelProps={{ shrink: true }}
                required
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="End Date"
                name="endDate"
                type="date"
                value={formValues.endDate || ''}
                onChange={handleFormChange}
                InputLabelProps={{ shrink: true }}
                required
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Coupon Code"
                name="couponCode"
                value={formValues.couponCode || ''}
                onChange={handleFormChange}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <Input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              {editingProduct && editingProduct.photo && !image && (
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                  <img 
                    src={editingProduct.photo} 
                    alt="Existing Preview" 
                    style={{ maxWidth: '100%', height: 'auto' }} 
                  />
                </Box>
              )}
              {image && <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <img src={URL.createObjectURL(image)} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />
              </Box>}
            </FormControl>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button type="submit" variant="contained" color="primary">Save</Button>
              <Button type="button" variant="outlined" color="secondary" onClick={handleCancelEdit}>Cancel</Button>
            </Box>
          </form>
        </Box>
      </Modal>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Link</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Coupon Code</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedProducts.map((product) => (
              <TableRow key={product._id}>
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.description}</TableCell>
                <TableCell>{product.link}</TableCell>
                <TableCell>{product.company}</TableCell>
                <TableCell>{formatDateDisplay(product.startDate)}</TableCell>
                <TableCell>{formatDateDisplay(product.endDate)}</TableCell>
                <TableCell>{product.couponCode}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditClick(product)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(product._id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={Math.ceil(filteredProducts.length / productsPerPage)}
          page={page}
          onChange={(e, value) => setPage(value)}
        />
      </Box>
    </Box>
  );
};

export default Products;
