// src/pages/ShopProduct.jsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Card, CardMedia, CardContent, Typography, Button, IconButton, Box, Backdrop } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CloseIcon from '@mui/icons-material/Close';
import { useCart } from '../hooks/CartContext';

// Sample product data for demonstration
const products = [
  { id: 1, name: 'Product 1', price: '20.00', image: 'https://via.placeholder.com/150', description: 'Description for product 1' },
  { id: 2, name: 'Product 2', price: '30.00', image: 'https://via.placeholder.com/150', description: 'Description for product 2' },
  // other products
];

const ShopProduct = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const { addToCart } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate fetching product data
    const foundProduct = products.find((product) => product.id === parseInt(id));
    setProduct(foundProduct);
  }, [id]);

  if (!product) {
    return <div>Loading...</div>;
  }

  const handleClose = () => {
    navigate('/shop');
  };

  const handleAddToCart = () => {
    addToCart(product);
  };

  return (
    <Backdrop open={true} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff', backdropFilter: 'blur(5px)' }}>
      <Container maxWidth="sm" sx={{ marginTop: 2, marginBottom: 4 }}>
        <Card sx={{ position: 'relative', padding: 2, boxShadow: 3, bgcolor: 'background.paper' }}>
          <Box sx={{ position: 'relative', padding: 1, marginBottom: 2 }}>
            <IconButton 
              onClick={handleClose} 
              sx={{ 
                position: 'absolute', 
                top: 0, 
                right: 0, 
                bgcolor: 'red', 
                color: 'white', 
                borderRadius: '50%', 
                '&:hover': { 
                  bgcolor: '#ff6f6f' // Light red color on hover
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
            <CardMedia
              component="img"
              image={product.image}
              alt={product.name}
              sx={{ width: '80%', maxHeight: '300px', objectFit: 'contain' }}
            />
          </Box>
          <CardContent>
            <Typography variant="h5" component="div">
              {product.name}
            </Typography>
            <Typography variant="h6" color="text.secondary">
              {product.price}
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              {product.description}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 3 }}>
              <Button 
                variant="contained" 
                color="primary" 
                sx={{ flexGrow: 1, marginRight: 1 }}
              >
                Buy Now
              </Button>
              <IconButton 
                color="primary" 
                aria-label="add to cart"
                onClick={handleAddToCart}
              >
                <AddShoppingCartIcon />
              </IconButton>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Backdrop>
  );
};

export default ShopProduct;
