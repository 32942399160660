import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Divider,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import Loading from "../components/Loading"; // Make sure the path is correct

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  marginBottom: theme.spacing(2),
  boxShadow: "0 3px 5px rgba(0,0,0,0.1)",
  alignItems: "center", // Vertically center the content
}));

const CompanyProduct = () => {
  const { storeName } = useParams();
  const navigate = useNavigate();
  const [company, setCompany] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompanyAndProducts = async () => {
      try {
        const [companyResponse, productsResponse] = await Promise.all([
          axios.get(
            process.env.REACT_APP_BACKEND_BASE_URL + `/company/${storeName}`
          ),
          axios.get(
            process.env.REACT_APP_BACKEND_BASE_URL +
              `/product/company/${storeName}`
          ),
        ]);

        setCompany(companyResponse.data);
        setProducts(productsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyAndProducts();
  }, [storeName]);

  if (loading) {
    return <Loading />;
  }

  if (!company) {
    return <Typography variant="h6">Company not found</Typography>;
  }

  const handleViewDetails = (productId) => {
    navigate(`/products/${productId}`);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <img
          src={company.logo}
          alt={company.name}
          style={{
            width: 100,
            height: 100,
            borderRadius: "50%",
            marginRight: 16,
          }}
        />
        <Box>
          <Typography variant="h5" component="div">
            {company.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {company.description}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mb: 3 }} />
      {products.map((product) => (
        <StyledCard key={product._id}>
          <img
            src={product.photo}
            alt={product.name}
            style={{ width: 150, height: 150, objectFit: "cover" }}
          />
          <CardContent sx={{ flex: "1 auto", ml: 2 }}>
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontSize: {
                  xs: "1.2rem", // Smaller font size for extra-small screens
                  sm: "1.5rem", // Slightly larger font size for small screens and up
                  md: "1.875rem", // Default font size for medium screens and up
                },
                lineHeight: {
                  xs: 1.3, // Adjust line height for extra-small screens
                  sm: 1.4, // Adjust line height for small screens and up
                  md: 1.5, // Default line height for medium screens and up
                },
              }}
            >
              {product.name}
            </Typography>

            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 2, display: { xs: "none", sm: "block" } }}
            >
              {product.description}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleViewDetails(product._id)}
            >
              View Details
            </Button>
          </CardContent>
        </StyledCard>
      ))}
    </Box>
  );
};

export default CompanyProduct;
