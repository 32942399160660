import React from 'react';
import { Button, Box, Typography, IconButton, Grid, Paper, useTheme, useMediaQuery, Checkbox } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useNavigate } from 'react-router-dom';
import { useCart } from '../hooks/CartContext';

const CartPage = () => {
  const { cartItems, removeFromCart, updateQuantity, toggleSelection } = useCart();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleIncrement = (id) => {
    updateQuantity(id, cartItems.find(item => item.id === id).quantity + 1);
  };

  const handleDecrement = (id) => {
    const item = cartItems.find(item => item.id === id);
    if (item.quantity > 1) {
      updateQuantity(id, item.quantity - 1);
    }
  };

  const handleRemove = (id) => {
    removeFromCart(id);
  };

  const handleSelect = (id) => {
    toggleSelection(id);
  };

  const handleRemoveSelected = () => {
    const itemsToRemove = cartItems.filter(item => item.selected);
    itemsToRemove.forEach(item => removeFromCart(item.id));
  };

  const handleBuyNow = (id) => {
    const item = cartItems.find(item => item.id === id);
    if (!sessionStorage.getItem('user_token')) {
      navigate('/login');
    } else if (item) {
      alert(`Buying ${item.name} for $${parseFloat(item.price).toFixed(2)}.`);
    }
  };

  const handleCheckout = () => {
    if (!sessionStorage.getItem('user_token')) {
      navigate('/login');
    } else {
      navigate('/checkout');
    }
  };

  const selectedItemsTotal = cartItems
    .filter(item => item.selected)
    .reduce((total, item) => total + parseFloat(item.price) * item.quantity, 0);

  const isCartEmpty = cartItems.length === 0;

  return (
    <Box sx={{ padding: 2, backgroundColor: '#f5f5f5' }}>
      {isCartEmpty ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 4 }}>
          <img 
            src={require('../assets/empty_cart.png')} 
            alt="Empty Cart" 
            style={{ width: '200px', height: '200px', objectFit: 'contain', marginBottom: 2 }}
          />
          <Typography 
            variant="h5" 
            sx={{ fontWeight: 'bold', color: '#333', marginBottom: 2 }}
          >
            Your Cart is Empty
          </Typography>
          <Button 
            variant="contained" 
            color="primary"
            component={Link}
            to="/shop"
            sx={{ backgroundColor: '#478ccf', color: 'white', '&:hover': { backgroundColor: '#357ab8' } }}
          >
            Shop Now
          </Button>
        </Box>
      ) : (
        <>
          <Typography 
            variant="h4" 
            gutterBottom 
            sx={{ 
              fontWeight: 'bold', 
              color: '#333', 
              textAlign: 'center',
              marginBottom: 4,
              fontSize: { xs: '1.5rem', sm: '2rem' },
            }}
          >
            Your Cart
          </Typography>
          <Grid container spacing={3}>
            {cartItems.map(item => (
              <Grid item xs={12} key={item.id}>
                <Paper 
                  sx={{ 
                    padding: 2, 
                    display: 'flex', 
                    flexDirection: isSmallScreen ? 'column' : 'row', 
                    alignItems: 'center', 
                    boxShadow: 3, 
                    borderRadius: 4,
                    backgroundColor: item.selected ? '#e3f2fd' : '#fff',
                    border: item.selected ? '2px solid #42a5f5' : '1px solid #ddd',
                    position: 'relative',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      boxShadow: 6,
                    }
                  }}
                >
                  {isSmallScreen && (
                    <Checkbox
                      checked={item.selected}
                      onChange={() => handleSelect(item.id)}
                      sx={{ position: 'absolute', top: 8, right: 8 }}
                    />
                  )}
                  <Box sx={{ 
                    flexShrink: 0, 
                    marginRight: isSmallScreen ? 0 : 2, 
                    marginBottom: isSmallScreen ? 2 : 0,
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                    <img 
                      src={item.image} 
                      alt={item.name} 
                      style={{ 
                        width: isSmallScreen ? 120 : 200, 
                        height: isSmallScreen ? 120 : 200, 
                        objectFit: 'cover', 
                        borderRadius: '8px',
                        border: '1px solid #ddd'
                      }} 
                    />
                  </Box>
                  <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontWeight: 'medium', 
                        color: '#444', 
                        display: 'flex', 
                        alignItems: 'center' 
                      }}
                    >
                      {item.name}
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: 1, color: '#666' }}>
                      ${parseFloat(item.price).toFixed(2)}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <IconButton 
                        onClick={() => handleDecrement(item.id)} 
                        sx={{ 
                          color: '#f50057', 
                          '&:hover': { backgroundColor: '#fce4ec' } 
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <Typography variant="body1" sx={{ margin: '0 8px' }}>
                        {item.quantity}
                      </Typography>
                      <IconButton 
                        onClick={() => handleIncrement(item.id)} 
                        sx={{ 
                          color: '#4caf50', 
                          '&:hover': { backgroundColor: '#e8f5e9' } 
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Button 
                        variant="outlined" 
                        color="error" 
                        onClick={() => handleRemove(item.id)} 
                        sx={{ borderColor: '#f50057', color: '#f50057', '&:hover': { borderColor: '#c51162', color: '#c51162' } }}
                      >
                        <DeleteIcon />
                        Remove
                      </Button>
                      <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={() => handleBuyNow(item.id)} 
                        sx={{ backgroundColor: '#478ccf', color: 'white', '&:hover': { backgroundColor: '#357ab8' } }}
                      >
                        Buy Now
                      </Button>
                    </Box>
                  </Box>
                  {!isSmallScreen && (
                    <Checkbox
                      checked={item.selected}
                      onChange={() => handleSelect(item.id)}
                      sx={{ ml: 2 }}
                    />
                  )}
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              padding: 2, 
              marginTop: 4, 
              backgroundColor: '#fff', 
              borderRadius: 4, 
              boxShadow: 3 
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
              Total: ${selectedItemsTotal.toFixed(2)}
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="outlined"
                color="error"
                sx={{ 
                  borderColor: '#f50057', 
                  color: '#f50057', 
                  '&:hover': { borderColor: '#c51162', color: '#c51162' }
                }}
                onClick={handleRemoveSelected}
              >
                Remove Selected
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCheckout}
                sx={{ backgroundColor: '#478ccf', color: 'white', '&:hover': { backgroundColor: '#357ab8' } }}
              >
                Checkout
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CartPage;
