// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import ShopProduct from './pages/ShopProduct';
import ProductDetailPage from './pages/ProductDetailPage';
import CartPage from './pages/CartPage';
import BlogPage from './pages/BlogPage';
import CheckoutPage from './pages/CheckoutPage';
import ContactUsPage from './pages/ContactUsPage';
import LoginPage from './pages/LoginPage';
import PaymentPage from './pages/PaymentPage';
import ProductsListPage from './pages/ProductsListPage';
import CompanyProduct from './pages/CompanyProduct';
import ThankYouPage from './pages/ThankYouPage';
import SearchPage from './pages/SearchPage'
import AdminDashboard from './admin/AdminDashboard';
import { CartProvider } from './hooks/CartContext'; // Import the CartProvider
import './App.css';
import AdminLogin from './admin/AdminLogin';

function App() {
  return (
    <Router>
      <CartProvider> {/* Wrap routes with CartProvider */}
        <div className="app-container">
          <Routes>
            {/* Admin routes */}
            <Route path="/admin" element={<AdminLogin />} />
            <Route path="/admin/dashboard/*" element={<AdminDashboard />} />

            {/* User routes */}
            <Route path="/" element={<><Header /><HomePage /><Footer /></>} />
            <Route path="/shop/:id" element={<><Header /><ShopProduct /><Footer /></>} />
            <Route path="/shop" element={<><Header /><ProductsListPage /><Footer /></>} />
            <Route path="/products/:id" element={<><Header /><ProductDetailPage /><Footer /></>} />
            <Route path="/cart" element={<><Header /><CartPage /><Footer /></>} />
            <Route path="/checkout" element={<><Header /><CheckoutPage /><Footer /></>} />
            <Route path="/search/:searchTerm" element={<><Header /><SearchPage /><Footer /></>} />
            <Route path="/contact" element={<><Header /><ContactUsPage /><Footer /></>} />
            <Route path="/blog" element={<><Header /><BlogPage /><Footer /></>} />
            <Route path="/login" element={<><Header /><LoginPage /><Footer /></>} />
            <Route path="/payment" element={<><Header /><PaymentPage /><Footer /></>} />
            <Route path="/thank-you" element={<><Header /><ThankYouPage /><Footer /></>} />
            <Route path="/companies-offer/:storeName" element={<><Header /><CompanyProduct /><Footer /></>} />
            
            {/* Redirect if no match */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </CartProvider>
    </Router>
  );
}

export default App;
