import React, { useState } from 'react';
import { Card, Typography, Button, CardMedia, Box, IconButton, Modal } from '@mui/material';
import { Close } from '@mui/icons-material';
import { format } from 'date-fns';

const BlogCard = ({ date, title, description, image }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'MMMM d, yyyy'); // Format as "August 9, 2024"
  };

  return (
    <>
      <Card sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'flex-start',
        maxWidth: '100%', // Ensure card does not exceed viewport width
        margin: 'auto',
        padding: 2,
        boxShadow: 3,
        borderRadius: 2,
        position: 'relative',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        overflow: 'hidden',
        '&:hover': {
          transform: 'translateY(-10px)',
          boxShadow: 6,
        }
      }}>
        {image && (
          <Box sx={{ 
            width: { xs: '100%', sm: 200 }, 
            height: { xs: 150, sm: 200 }, 
            overflow: 'hidden', 
            borderRadius: 1,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', // 3D shadow effect
            marginBottom: { xs: 2, sm: 2 },
          }}>
            <CardMedia
              component="img"
              image={image}
              alt="Blog image"
              sx={{ 
                width: '100%', 
                height: '100%', 
                objectFit: 'cover'
              }}
            />
          </Box>
        )}
        <Box sx={{ 
          flex: 1, 
          padding: 2, 
          display: 'flex', 
          flexDirection: 'column', 
          position: 'relative',
          minHeight: { xs: 150, sm: 200 }, // Adjust minimum height for small screens
        }}>
          <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 1 }}>
            {formatDate(date)}
          </Typography>
          <Typography 
            variant="h6" // Adjusted title size for better readability on small screens
            component="div" 
            sx={{ 
              fontWeight: 'bold', 
              marginBottom: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {title}
          </Typography>
          <Typography 
            variant="body2" 
            color="text.secondary" 
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flex: 1,
              marginBottom: 2,
              textAlign: 'justify'
            }}
          >
            {description}
          </Typography>
          <Button 
            variant="outlined" 
            color="primary" 
            onClick={handleOpen}
            sx={{ 
              textTransform: 'none', 
              fontWeight: 'bold', 
              alignSelf: 'flex-start'
            }}
          >
            Continue Reading
          </Button>
        </Box>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backdropFilter: 'blur(5px)',
          overflow: 'auto'
        }}
      >
        <Card sx={{ 
          width: { xs: '90vw', sm: '70vw' }, // Responsive width for the modal
          maxWidth: '90vw', // Ensure it scales well on smaller screens
          maxHeight: '90vh', // Ensure it doesn't exceed the viewport height
          padding: 0,
          borderRadius: 2,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          boxShadow: 8,
          backgroundColor: 'background.paper',
        }}>
          <Box 
            sx={{ 
              position: 'relative', 
              width: '100%', 
              height: { xs: 'auto', sm: 300 }, // Responsive height for the image
              maxHeight: { xs: 200, sm: 300 }, // Ensure image does not exceed container height
              overflow: 'hidden',
              marginBottom: 2, // Margin between image and content
              borderBottom: '5px solid rgba(0, 0, 0, 0.2)', // 3D border
              boxShadow: '0 12px 24px rgba(0, 0, 0, 0.5)', // Enhanced 3D shadow effect
              padding: 2, // Padding around the image
              display: 'flex',
              justifyContent: 'center', // Center image horizontally
              alignItems: 'center' // Center image vertically
            }}
          >
            {image && (
              <CardMedia
                component="img"
                image={image}
                alt="Blog image"
                sx={{ 
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain', // Ensure the image fits within the container without cropping
                  borderRadius: 1, // Rounded corners
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', // Subtle shadow around the image
                  padding: 1 // Padding inside the image container
                }}
              />
            )}
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: 16,
                right: 16,
                color: 'text.primary',
                backgroundColor: 'background.paper',
                borderRadius: '50%',
                padding: 1,
                boxShadow: 2,
                zIndex: 1200, // Ensure the button is above other elements
                '&:hover': {
                  backgroundColor: 'grey.200',
                  boxShadow: 4
                }
              }}
            >
              <Close />
            </IconButton>
          </Box>
          <Box sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto', // Make the content area scrollable
            padding: 3,
            maxHeight: 'calc(90vh - 200px)' // Account for fixed image height
          }}>
            <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 1 }}>
              {formatDate(date)}
            </Typography>
            <Typography 
              variant="h5" // Adjusted title size in modal
              component="div" 
              sx={{ 
                fontWeight: 'bold', 
                marginBottom: 1
              }}
            >
              {title}
            </Typography>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ textAlign: 'justify' }}
            >
              {description}
            </Typography>
          </Box>
        </Card>
      </Modal>
    </>
  );
};

export default BlogCard;
