import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Input, InputLabel, FormControl, FormHelperText, Alert } from '@mui/material';
import axios from 'axios';

const AddCompany = () => {
  const [formData, setFormData] = useState({
    name: '',
    logo: null,
    description: '',
  });
  const [message, setMessage] = useState(null); // State for handling messages

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(null); // Clear previous messages
  
    const { name, logo, description } = formData;
  
    if (!logo) {
      setMessage({ text: 'Logo is required', type: 'error' });
      return;
    }
  
    const reader = new FileReader();
  
    reader.onloadend = async () => {
      const base64Logo = reader.result;
  
      const dataToSend = {
        name,
        logo: base64Logo,
        description: description || null,
      };
  
      const adminToken = sessionStorage.getItem('admin_token');
  
      try {
        const response = await axios.post(process.env.REACT_APP_BACKEND_BASE_URL+'/company', dataToSend, {
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': adminToken,
          },
        });
        setMessage({ text: 'Company added successfully!', type: 'success' });
        console.log('Company added successfully:', response.data);
  
        // Clear form data
        setFormData({
          name: '',
          logo: null,
          description: '',
        });
  
      } catch (error) {
        setMessage({ text: `Error: ${error.response?.data?.msg || 'Failed to add company'}`, type: 'error' });
        console.error('Error adding company:', error.response?.data || error);
      }
    };
  
    reader.readAsDataURL(logo);
  };
  
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: '100%',
        maxWidth: '600px',
        mx: 'auto',
        p: 3,
        border: '1px solid #478ccf',
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        sx={{ textAlign: 'center', mb: 3, color: '#478ccf' }}
      >
        Add Company
      </Typography>
      {message && (
        <Alert
          severity={message.type === 'success' ? 'success' : 'error'}
          sx={{ mb: 2 }}
        >
          {message.text}
        </Alert>
      )}
      <TextField
        label="Company Name"
        name="name"
        variant="outlined"
        fullWidth
        required
        value={formData.name}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel shrink htmlFor="logo-upload">
          Upload Logo
        </InputLabel>
        <Input
          id="logo-upload"
          name="logo"
          type="file"
          required
          onChange={handleChange}
          sx={{
            '&:hover': { backgroundColor: '#f1f1f1' },
            padding: '10px',
            border: '1px solid #478ccf',
            borderRadius: 2,
            mt: 1,
          }}
        />
        <FormHelperText sx={{ color: 'red', mt: 1 }}>
          If possible, please upload a PNG image.
        </FormHelperText>
      </FormControl>
      <TextField
        label="Description"
        name="description"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        value={formData.description}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <Box sx={{ textAlign: 'center', mt: 3 }}>
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: '#478ccf',
            '&:hover': { backgroundColor: '#35699f' },
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default AddCompany;
