import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from 'axios';
import { Card, CardContent, CardMedia, Typography, Grid, Button, Container, Paper, Box } from '@mui/material';
import notFoundImage from '../assets/not_found.png'; // Import the not found image

const SearchPage = () => {
  const { searchTerm } = useParams(); // Extract searchTerm from the URL
  const navigate = useNavigate(); // Initialize useNavigate
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL+`/product?search=${encodeURIComponent(searchTerm)}`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [searchTerm]);

  const handleMoreInfoClick = (productId) => {
    navigate(`/products/${productId}`);
  };

  const renderProductCard = (product) => (
    <Grid item xs={12} sm={6} md={4} lg={3} key={product._id}>
      <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', boxShadow: 3 }}>
        {product.photo && (
          <CardMedia
            component="img"
            alt={product.name}
            height="200"
            image={product.photo}
            title={product.name}
            sx={{ objectFit: 'contain', padding: 2, backgroundColor: '#f5f5f5' }}
          />
        )}
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
            {product.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1 }}>
            {product.description}
          </Typography>
          {product.price && (
            <Typography variant="body1" color="textPrimary" sx={{ fontWeight: 'medium', marginBottom: 1 }}>
              Price: ${product.price}
            </Typography>
          )}
          {product.company && (
            <Typography variant="body1" color="textPrimary" sx={{ fontWeight: 'medium', marginBottom: 1 }}>
              Company: {product.company}
            </Typography>
          )}
          {product.productType === 'internal' && product.startDate && (
            <Typography variant="body1" color="textPrimary" sx={{ fontWeight: 'medium', marginBottom: 1 }}>
              Start Date: {new Date(product.startDate).toLocaleDateString()}
            </Typography>
          )}
          {product.productType === 'internal' && product.endDate && (
            <Typography variant="body1" color="textPrimary" sx={{ fontWeight: 'medium', marginBottom: 2 }}>
              End Date: {new Date(product.endDate).toLocaleDateString()}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleMoreInfoClick(product._id)} // Navigate on click
            sx={{ marginTop: 'auto', alignSelf: 'flex-start' }}
          >
            More Info
          </Button>
        </CardContent>
      </Card>
    </Grid>
  );

  const internalProducts = products.filter(product => product.productType === 'internal');
  const externalProducts = products.filter(product => product.productType === 'external');

  const hasResults = internalProducts.length > 0 || externalProducts.length > 0;

  return (
    <Container>
      <Typography variant="h4" gutterBottom sx={{ marginTop: 4, textAlign: 'center', fontWeight: 'bold' }}>
        Search Results for: {decodeURIComponent(searchTerm)}
      </Typography>
      
      {hasResults ? (
        <>
          {internalProducts.length > 0 && (
            <Paper elevation={3} sx={{ padding: 4, marginY: 4, backgroundColor: '#f9f9f9' }}>
              <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                Shop with Plunk.com
              </Typography>
              <Grid container spacing={4}>
                {internalProducts.map((product) => renderProductCard(product))}
              </Grid>
            </Paper>
          )}

          {externalProducts.length > 0 && (
            <Paper elevation={3} sx={{ padding: 4, marginY: 4, backgroundColor: '#f9f9f9' }}>
              <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                Discount Product Links
              </Typography>
              <Grid container spacing={4}>
                {externalProducts.map((product) => renderProductCard(product))}
              </Grid>
            </Paper>
          )}
        </>
      ) : (
        <Container sx={{ textAlign: 'center', marginTop: 4 }}>
          <Box sx={{ marginBottom: 2 }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
              No products found for "{decodeURIComponent(searchTerm)}"
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ marginBottom: 2 }}>
              We couldn't find any products matching your search. Please try a different search term.
            </Typography>
          </Box>
          <CardMedia
            component="img"
            alt="Not Found"
            image={notFoundImage} // Use the imported image
            sx={{ maxWidth: '400px', margin: '0 auto', marginTop: 2 }}
          />
        </Container>
      )}
    </Container>
  );
};

export default SearchPage;
