import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Input, InputLabel, FormControl, FormControlLabel, Radio, RadioGroup, Alert } from '@mui/material';

const AddProducts = () => {
  const [productType, setProductType] = useState('external'); // Default product type
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    description: '',
    link: '',
    couponCode: '', // Add couponCode to formData
    startDate: '',
    endDate: '',
    price: '',
    photo: null,
  });
  const [message, setMessage] = useState(null); // State for handling messages

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(null); // Clear previous messages

    try {
      const formDataObj = new FormData();
      formDataObj.append('name', formData.name);
      formDataObj.append('company', formData.company.toLowerCase());
      formDataObj.append('description', formData.description);
      formDataObj.append('link', formData.link);
      formDataObj.append('couponCode', formData.couponCode);
      formDataObj.append('startDate', formData.startDate);
      formDataObj.append('endDate', formData.endDate);
      formDataObj.append('price', formData.price);
      if (formData.photo) {
        formDataObj.append('photo', formData.photo);
      }
      formDataObj.append('productType', productType);

      console.log(formDataObj.get("name"))
      console.log(formDataObj.get("company"))
      console.log(formDataObj.get("description"))
      console.log(formDataObj.get("link"))
      console.log(formDataObj.get("couponCode"))
      console.log(formDataObj.get("startDate"))
      console.log(formDataObj.get("endDate"))
      console.log(formDataObj.get("productType"))

      const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/product`, {
        method: 'POST',
        headers: {
          'x-auth-token': sessionStorage.getItem('admin_token') || '', // Include the token here
        },
        body: formDataObj,
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(`Failed to add product: ${data.message || 'Unknown error'}`);
      }

      setMessage({ text: 'Product added successfully!', type: 'success' });

      // Clear form data
      setFormData({
        name: '',
        company: '',
        description: '',
        link: '',
        couponCode: '',
        startDate: '',
        endDate: '',
        price: '',
        photo: null,
      });
      setProductType('external'); // Reset product type to default

    } catch (error) {
      setMessage({ text: `Error: ${error.message}`, type: 'error' });
      console.error('Error:', error);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      encType="multipart/form-data"
      sx={{
        width: '100%',
        maxWidth: '600px',
        mx: 'auto',
        p: 3,
        border: '1px solid #478ccf',
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        sx={{ textAlign: 'center', mb: 3, color: '#478ccf' }}
      >
        Add Product
      </Typography>
      <FormControl component="fieldset" sx={{ mb: 3 }}>
        <RadioGroup
          row
          name="productType"
          value={productType}
          onChange={(e) => setProductType(e.target.value)}
        >
          <FormControlLabel
            value="external"
            control={<Radio />}
            label="External Product"
          />
          <FormControlLabel
            value="internal"
            control={<Radio />}
            label="Our Product"
          />
        </RadioGroup>
      </FormControl>
      <TextField
        label="Name"
        name="name"
        variant="outlined"
        fullWidth
        required
        value={formData.name}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      {productType === 'external' && (
        <>
          <TextField
            label="Company"
            name="company"
            variant="outlined"
            fullWidth
            required
            value={formData.company}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Description"
            name="description"
            variant="outlined"
            fullWidth
            required
            value={formData.description}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Link"
            name="link"
            variant="outlined"
            fullWidth
            required
            value={formData.link}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Coupon Code"
            name="couponCode" // Add couponCode field
            variant="outlined"
            fullWidth
            value={formData.couponCode}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Start Date"
            name="startDate"
            type="date"
            variant="outlined"
            fullWidth
            required
            InputLabelProps={{ shrink: true }}
            value={formData.startDate}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <TextField
            label="End Date"
            name="endDate"
            type="date"
            variant="outlined"
            fullWidth
            required
            InputLabelProps={{ shrink: true }}
            value={formData.endDate}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
        </>
      )}
      {productType === 'internal' && (
        <>
          <TextField
            label="Price"
            name="price"
            variant="outlined"
            fullWidth
            required
            value={formData.price}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Description"
            name="description"
            variant="outlined"
            fullWidth
            required
            value={formData.description}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
        </>
      )}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel shrink htmlFor="photo-upload">
          Upload Photo
        </InputLabel>
        <Input
          id="photo-upload"
          name="photo"
          type="file"
          onChange={handleChange}
          sx={{
            '&:hover': { backgroundColor: '#f1f1f1' },
            padding: '10px',
            border: '1px solid #478ccf',
            borderRadius: 2,
            mt: 1,
          }}
        />
      </FormControl>
      {message && (
        <Alert
          severity={message.type === 'success' ? 'success' : 'error'}
          sx={{ mb: 2 }}
        >
          {message.text}
        </Alert>
      )}
      <Box sx={{ textAlign: 'center', mt: 3 }}>
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: '#478ccf',
            '&:hover': { backgroundColor: '#35699f' },
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default AddProducts;
