import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Card, CardMedia, CardContent, Typography, Button, Grid, IconButton, Box } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../hooks/CartContext';

const ProductsListPage = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const { addToCart } = useCart();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL+'/product');
        const filteredProducts = response.data.filter(product => product.productType === 'internal')
        const transformedProducts = filteredProducts.map(product => ({
          id: product._id,
          name: product.name,
          price: product.price,
          description: product.description,
          image: product.photo,
        }));
        setProducts(transformedProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleViewDetail = (id) => {
    navigate(`/shop/${id}`);
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: 2, marginBottom: 4 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 3,
          textAlign: 'center',
        }}
      >
        <EmojiEmotionsIcon sx={{ fontSize: 40, marginRight: 1, color: '#FFD700' }} />
        <Typography
          variant="h4"
          component="h1"
          sx={{
            fontWeight: 'bold',
            color: '#3f51b5',
            fontFamily: 'Arial, sans-serif',
            letterSpacing: 1.5,
          }}
        >
          Shop From Coupon World
        </Typography>
        <LocalOfferIcon sx={{ fontSize: 40, marginLeft: 1, color: '#FFD700' }} />
      </Box>
      <Grid container spacing={2}>
        {products.map((product) => (
          <Grid item xs={12} sm={6} md={4} lg={2} xl={2} key={product.id}>
            <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <CardMedia
                component="img"
                height="140"
                image={product.image}
                alt={product.name}
              />
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography variant="h6" component="div">
                  {product.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {product.price}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      flexGrow: 1,
                      marginRight: 1,
                      backgroundColor: '#3f51b5',
                      '&:hover': {
                        backgroundColor: '#303f9f',
                      },
                    }}
                    onClick={() => handleViewDetail(product.id)}
                  >
                    View Detail
                  </Button>
                  <IconButton
                    color="primary"
                    aria-label="add to cart"
                    onClick={() => addToCart(product)}
                  >
                    <AddShoppingCartIcon />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ProductsListPage;
