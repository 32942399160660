// src/pages/ThankYouPage.jsx
import React from 'react';
import { useCart } from '../hooks/CartContext';
import BillDownloader from '../components/BillDownloader';

const ThankYouPage = () => {
  const { cartItems, deliveryInfo, billingInfo } = useCart();

  return (
    <div>
      <h1>Thank You for Your Purchase!</h1>
      <BillDownloader cartItems={cartItems} deliveryInfo={deliveryInfo} billingInfo={billingInfo} />
    </div>
  );
};

export default ThankYouPage;
