import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Backdrop,
  Paper,
  Typography,
  Button,
  Container,
  IconButton,
  Box,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import CheckIcon from "@mui/icons-material/Check";
import Shopping1Svg from "../assets/shopping1.png";
import Shopping2Svg from "../assets/shopping2.png";
import Loading from "../components/Loading"; // Make sure the path is correct

const ProductDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumOrLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [product, setProduct] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(true);
  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productResponse = await fetch(
          process.env.REACT_APP_BACKEND_BASE_URL + `/product/${id}`
        );
        if (!productResponse.ok) {
          throw new Error("Network response was not ok");
        }
        const productData = await productResponse.json();
        setProduct(productData);
        console.log(productData);
      } catch (error) {
        console.error("Failed to fetch data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const handleClose = () => {
    navigate(-1);
  };

  const handleImageError = () => {
    setImageLoaded(false);
  };

  const handleGetDealClick = () => {
    if (product && product.link) {
      window.open(product.link, "_blank");
    }
  };

  const handleCopyCouponCode = () => {
    if (product && product.couponCode) {
      navigator.clipboard.writeText(product.couponCode);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Revert to copy icon after 2 seconds
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (!product) {
    return <Typography variant="h6">Product not found</Typography>;
  }

  return (
    <Backdrop
      open={true}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        color: "#fff",
        backdropFilter: "blur(5px)",
      }}
    >
      <Container
        maxWidth={isSmallScreen ? "sm" : "md"}
        sx={{ marginTop: 2, marginBottom: 4 }}
      >
        <Paper
          elevation={6}
          sx={{
            position: "relative",
            padding: isSmallScreen ? 2 : 3,
            boxShadow: 3,
            borderRadius: 3,
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            alignItems: isSmallScreen ? "flex-start" : "center",
            minHeight: isSmallScreen ? "auto" : "400px",
            gap: isSmallScreen ? 1 : 2,
            maxWidth: isMediumOrLargeScreen ? "lg" : "100%",
            margin: "0 auto",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              bgcolor: "error.main",
              color: "white",
              borderRadius: "50%",
              "&:hover": { bgcolor: "error.dark" },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box
            sx={{
              flex: "1",
              padding: isSmallScreen ? 1 : 2,
              borderRadius: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "300px",
              overflow: "hidden",
            }}
          >
            <img
              src={
                imageLoaded
                  ? product.photo
                  : product.id % 2 === 0
                  ? Shopping2Svg
                  : Shopping1Svg
              }
              alt={product.name}
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                height: "auto",
                borderRadius: "15px",
                border: "2px solid #d1d1d1",
                boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                objectFit: "cover",
              }}
              onError={handleImageError}
            />
          </Box>

          <Box
            sx={{
              flex: "1",
              marginLeft: isSmallScreen ? 0 : 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: isSmallScreen ? "flex-start" : "center",
              gap: isSmallScreen ? 1 : 2,
              width: "100%",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                margin: "10px 0",
                color: theme.palette.text.primary,
                textTransform: "uppercase",
                letterSpacing: "1.5px",
                textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
                fontSize: isSmallScreen ? "1.5rem" : "inherit", // Adjust font size for small screens
              }}
            >
              {product.name}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginBottom: "20px",
                color: theme.palette.text.secondary,
                lineHeight: 1.8,
                fontSize: isSmallScreen ? "0.875rem" : "1rem", // Adjust font size for small screens
                fontFamily: "Roboto, Arial, sans-serif",
              }}
            >
              {product.description}
            </Typography>

            {/* Coupon Code Section */}
            {product.couponCode && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  padding: 2,
                  borderRadius: 2,
                  border: "1px solid #d1d1d1",
                  bgcolor: "background.paper",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  fontSize: isSmallScreen ? "0.875rem" : "1rem", // Adjust font size for small screens
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: isSmallScreen ? "0.875rem" : "1rem", // Adjust font size for small screens
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  Coupon Code: {product.couponCode}
                </Typography>
                <Tooltip title={copied ? "Copied!" : "Copy to clipboard"} arrow>
                  <IconButton onClick={handleCopyCouponCode}>
                    {copied ? (
                      <CheckIcon color="success" />
                    ) : (
                      <CopyAllIcon color="action" />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            )}

            <Button
              variant="contained"
              sx={{
                backgroundColor: "error.main",
                color: "white",
                width: "100%",
                borderRadius: "8px",
                fontWeight: "bold",
                fontSize: isSmallScreen ? "0.875rem" : "1rem", // Adjust font size for small screens
                "&:hover": { backgroundColor: "error.dark" },
              }}
              endIcon={
                <PanToolAltIcon
                  sx={{
                    fontSize: isSmallScreen ? 20 : 24,
                    color: "yellow",
                    transform: "rotate(90deg)", // Rotate the icon to point right
                    transition: "transform 0.3s", // Optional: add a transition effect for smooth rotation
                  }}
                />
              }
              onClick={handleGetDealClick}
            >
              Get Deal
            </Button>
          </Box>
        </Paper>
      </Container>
    </Backdrop>
  );
};

export default ProductDetailPage;
