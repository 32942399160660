import React, { useState, useEffect } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, IconButton, Modal, FormControl, Button, CircularProgress, Pagination } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingCompany, setEditingCompany] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [logo, setLogo] = useState(null);

  // Pagination state
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + '/company', {
          headers: {
            'x-auth-token': sessionStorage.getItem('admin_token'),
          },
        });
        setCompanies(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch companies');
        setLoading(false);
      }
    };

    fetchCompanies();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDelete = async (id) => {
    try {
      const token = sessionStorage.getItem('admin_token');
      await axios.delete(process.env.REACT_APP_BACKEND_BASE_URL + `/company/${id}`, {
        headers: { 'x-auth-token': token }
      });
      setCompanies(companies.filter(company => company._id !== id));
    } catch (err) {
      setError('Failed to delete company');
    }
  };

  const handleEditClick = (company) => {
    setEditingCompany(company);
    setFormValues({ ...company });
    setLogo(company.logo);
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditingCompany(null);
    setFormValues({});
    setLogo(null);
  };

  const handleFormChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem('admin_token');
      await axios.put(process.env.REACT_APP_BACKEND_BASE_URL + `/company/${editingCompany._id}`, {
        ...formValues,
        logo: logo || editingCompany.logo, // Use new logo if uploaded, else use old logo
      }, {
        headers: { 'x-auth-token': token }
      });
      setCompanies(companies.map(company =>
        company._id === editingCompany._id ? { ...company, ...formValues, logo: logo || editingCompany.logo } : company
      ));
      handleCancelEdit();
    } catch (err) {
      setError('Failed to update company');
    }
  };

  // Pagination handlers
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // const handleRowsPerPageChange = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(1); // Reset to first page
  // };

  const filteredCompanies = companies.filter((company) =>
    company.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedCompanies = filteredCompanies.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1">Companies</Typography>
        <TextField
          label="Search"
          variant="outlined"
          sx={{ width: '300px' }}
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Box>

      {/* Edit Form Modal */}
      <Modal
        open={isEditing}
        onClose={handleCancelEdit}
        aria-labelledby="edit-company-title"
        aria-describedby="edit-company-description"
      >
        <Box
          sx={{
            p: 3,
            bgcolor: 'background.paper',
            mx: 'auto',
            mt: 5,
            maxWidth: '600px',
            borderRadius: 1,
            overflow: 'auto',
            maxHeight: '80vh'
          }}
        >
          <Typography id="edit-company-title" variant="h6" component="h2">
            Edit Company
          </Typography>
          <form onSubmit={handleFormSubmit}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Name"
                name="name"
                value={formValues.name || ''}
                onChange={handleFormChange}
                required
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Description"
                name="description"
                value={formValues.description || ''}
                onChange={handleFormChange}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <input
                type="file"
                accept="image/*"
                onChange={handleLogoChange}
                style={{ marginBottom: '10px' }}
              />
              {logo && <img src={logo} alt="Preview" style={{ maxWidth: '100px' }} />}
            </FormControl>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button variant="contained" type="submit">Update</Button>
              <Button variant="outlined" onClick={handleCancelEdit}>Cancel</Button>
            </Box>
          </form>
        </Box>
      </Modal>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="companies table">
          <TableHead sx={{ backgroundColor: '#478ccf' }}>
            <TableRow>
              <TableCell sx={{ color: 'white' }}>Name</TableCell>
              <TableCell sx={{ color: 'white' }}>Logo</TableCell>
              <TableCell sx={{ color: 'white' }}>Description</TableCell>
              <TableCell align="center" sx={{ color: 'white' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedCompanies.map((company) => (
              <TableRow
                key={company._id}
                sx={{
                  '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                  '&:hover': { backgroundColor: '#f1f1f1' },
                }}
              >
                <TableCell>{company.name}</TableCell>
                <TableCell>
                  <Box
                    component="img"
                    src={company.logo}
                    alt={company.name}
                    sx={{
                      width: 100,
                      height: 100,
                      objectFit: 'contain',
                      borderRadius: 1,
                      display: 'block',
                    }}
                  />
                </TableCell>
                <TableCell>{company.description || 'No description'}</TableCell>
                <TableCell align="center">
                  <IconButton aria-label="edit" onClick={() => handleEditClick(company)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    sx={{ color: 'red' }}
                    onClick={() => handleDelete(company._id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Pagination
          count={Math.ceil(filteredCompanies.length / rowsPerPage)}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default Companies;
