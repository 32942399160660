import React, { useState } from 'react';
import { Box, Button, Typography, TextField, Grid, Paper, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../hooks/CartContext';

const CheckoutPage = () => {
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});
  const { cartItems, setDeliveryInfo, setBillingInfo, deliveryInfo, billingInfo } = useCart();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const validateDeliveryInfo = () => {
    const newErrors = {};
    if (!deliveryInfo.name) newErrors.name = 'Name is required';
    if (!deliveryInfo.address) newErrors.address = 'Address is required';
    if (!deliveryInfo.city) newErrors.city = 'City is required';
    if (!deliveryInfo.country) newErrors.country = 'Country is required';
    if (!deliveryInfo.postalCode) newErrors.postalCode = 'Postal Code is required';
    if (!deliveryInfo.phone) newErrors.phone = 'Phone is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleDeliveryInfoChange = (e) => {
    setDeliveryInfo({
      ...deliveryInfo,
      [e.target.name]: e.target.value,
    });
    setErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: '' }));
  };

  const handleNextStep = () => {
    if (step === 1 && !validateDeliveryInfo()) return;
    if (step === 2) {
      const total = cartItems.filter((item) => item.selected).reduce((acc, item) => acc + parseFloat(item.price) * item.quantity, 0);
      setBillingInfo({ total });
    }
    setStep(step + 1);
  };

  const handleBackStep = () => {
    setStep(step - 1);
  };

  const handlePayNow = () => {
    navigate('/payment');
  };

  return (
    <Box sx={{ padding: 2, backgroundColor: '#f5f5f5' }}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', marginBottom: 4 }}>
        Checkout
      </Typography>
      <Paper sx={{ padding: 3, marginBottom: 3, borderRadius: 2 }}>
        {step === 1 && (
          <>
            <Typography variant="h6" gutterBottom>
              Step 1: Delivery Information
            </Typography>
            <TextField
              label="Name"
              name="name"
              value={deliveryInfo.name}
              onChange={handleDeliveryInfoChange}
              fullWidth
              margin="normal"
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField
              label="Address"
              name="address"
              value={deliveryInfo.address}
              onChange={handleDeliveryInfoChange}
              fullWidth
              margin="normal"
              error={!!errors.address}
              helperText={errors.address}
            />
            <TextField
              label="City"
              name="city"
              value={deliveryInfo.city}
              onChange={handleDeliveryInfoChange}
              fullWidth
              margin="normal"
              error={!!errors.city}
              helperText={errors.city}
            />
            <TextField
              label="Country"
              name="country"
              value={deliveryInfo.country}
              onChange={handleDeliveryInfoChange}
              fullWidth
              margin="normal"
              error={!!errors.country}
              helperText={errors.country}
            />
            <TextField
              label="Postal Code"
              name="postalCode"
              value={deliveryInfo.postalCode}
              onChange={handleDeliveryInfoChange}
              fullWidth
              margin="normal"
              error={!!errors.postalCode}
              helperText={errors.postalCode}
            />
            <TextField
              label="Phone"
              name="phone"
              value={deliveryInfo.phone}
              onChange={handleDeliveryInfoChange}
              fullWidth
              margin="normal"
              error={!!errors.phone}
              helperText={errors.phone}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNextStep}
                sx={{ backgroundColor: '#478ccf', color: 'white', '&:hover': { backgroundColor: '#357ab8' } }}
                disabled={!deliveryInfo.name || !deliveryInfo.address || !deliveryInfo.city || !deliveryInfo.country || !deliveryInfo.postalCode || !deliveryInfo.phone}
              >
                Next
              </Button>
            </Box>
          </>
        )}

        {step === 2 && (
          <>
            <Typography variant="h6" gutterBottom>
              Step 2: Confirm Your Products
            </Typography>
            {cartItems.filter((item) => item.selected).length === 0 ? (
              <Typography variant="body1" sx={{ textAlign: 'center', marginTop: 2 }}>
                No products selected.
              </Typography>
            ) : (
              <Grid container spacing={2}>
                {cartItems.filter((item) => item.selected).map((item) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                    <Paper sx={{ padding: 2, display: 'flex', alignItems: 'center' }}>
                      <img
                        src={item.image}
                        alt={item.name}
                        style={{ width: 80, height: 80, objectFit: 'cover', marginRight: 16 }}
                      />
                      <Box>
                        <Typography variant="body1">{item.name}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          ${parseFloat(item.price).toFixed(2)} x {item.quantity}
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleBackStep}
                sx={{ borderColor: '#478ccf', color: '#478ccf', '&:hover': { borderColor: '#357ab8', color: '#357ab8' } }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNextStep}
                sx={{ backgroundColor: '#478ccf', color: 'white', '&:hover': { backgroundColor: '#357ab8' } }}
              >
                Next
              </Button>
            </Box>
          </>
        )}

        {step === 3 && (
          <>
            <Typography variant="h6" gutterBottom>
              Step 3: Billing Information
            </Typography>
            <Typography variant="h6" gutterBottom>
              Total: ${billingInfo.total.toFixed(2)}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePayNow}
                sx={{ backgroundColor: '#478ccf', color: 'white', '&:hover': { backgroundColor: '#357ab8' } }}
              >
                Pay Now
              </Button>
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default CheckoutPage;
