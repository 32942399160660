// LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import { Box, Button, TextField, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios'; // Import Axios for HTTP requests
import Illustration from '../assets/login_page.png';

const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL+'/auth';

const LoginPageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const IllustrationContainer = styled(Box)(({ theme }) => ({
  width: '60%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const IllustrationImage = styled('img')({
  maxWidth: '100%',
  height: 'auto',
});

const FormContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: theme.shape?.borderRadius || 4,
  boxShadow: theme.shadows?.[3] || '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  padding: theme.spacing(4),
  border: '1px solid #4535c1',
  width: '80%',
  maxWidth: '400px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: theme.spacing(2),
  },
}));

const ToggleButton = styled(Button)(({ theme, active }) => ({
  backgroundColor: active ? '#4535c1' : '#f0f0f0',
  color: active ? '#fff' : '#333',
  border: active ? `1px solid #4535c1` : 'none',
  width: '100%',
  marginBottom: theme.spacing(2),
  '&:hover': {
    backgroundColor: active ? '#3a2c9d' : '#e0e0e0',
    color: active ? '#fff' : '#4535c1',
    border: active ? `1px solid #3a2c9d` : 'none',
  },
}));

const LoginPage = () => {
  const [authType, setAuthType] = useState('login');
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    confirmPassword: '',
  });
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMsg(''); // Clear previous error messages
    try {
      let endpoint = '';
      let data = {};

      switch (authType) {
        case 'login':
          endpoint = '/login';
          data = { email: formData.email, password: formData.password };
          break;
        case 'signup':
          endpoint = '/register';
          data = { name: formData.name, email: formData.email, password: formData.password, confirmPassword: formData.confirmPassword };
          break;
        default:
          return;
      }

      const response = await axios({
        method: "post",
        baseURL: backendBaseUrl,
        url: endpoint,
        data: data
      });

      if (response.data.token) {
        sessionStorage.setItem('user_token', response.data.token);
        navigate('/');
      } else {
        setMsg(response.data.msg || 'Unknown error occurred');
      }
    } catch (error) {
      setMsg(error.response?.data?.msg || 'An error occurred. Please try again.');
      console.error('Error:', error); // Handle error gracefully
    }
  };

  return (
    <LoginPageContainer>
      <IllustrationContainer>
        <IllustrationImage src={Illustration} alt="Illustration" />
      </IllustrationContainer>
      <FormContainer component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item xs={12} sm={6}>
            <ToggleButton fullWidth active={authType === 'login'} onClick={() => setAuthType('login')}>Login</ToggleButton>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ToggleButton fullWidth active={authType === 'signup'} onClick={() => setAuthType('signup')}>Signup</ToggleButton>
          </Grid>
        </Grid>
        {authType === 'login' && (
          <Box style={{ width: '100%' }}>
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              variant="outlined"
              name="email"
              value={formData.email}
              onChange={handleChange}
              sx={{
                marginBottom: 2,
                height: { xs: 48, sm: 56 }, // Adjust height for different screen sizes
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Password"
              variant="outlined"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              sx={{
                marginBottom: 2,
                height: { xs: 48, sm: 56 }, // Adjust height for different screen sizes
              }}
            />
            <FormControlLabel control={<Checkbox />} label="Remember me" sx={{ marginBottom: 2 }} />
            <Button type="submit" fullWidth variant="contained" sx={{ backgroundColor: '#4535c1', color: '#fff' }}>Login</Button>
            {msg && <Typography color="error" variant="body2">{msg}</Typography>}
          </Box>
        )}
        {authType === 'signup' && (
          <Box style={{ width: '100%' }}>
            <TextField
              fullWidth
              margin="normal"
              label="Name"
              variant="outlined"
              name="name"
              value={formData.name}
              onChange={handleChange}
              sx={{
                marginBottom: 2,
                height: { xs: 48, sm: 56 }, // Adjust height for different screen sizes
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              variant="outlined"
              name="email"
              value={formData.email}
              onChange={handleChange}
              sx={{
                marginBottom: 2,
                height: { xs: 48, sm: 56 }, // Adjust height for different screen sizes
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Password"
              variant="outlined"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              sx={{
                marginBottom: 2,
                height: { xs: 48, sm: 56 }, // Adjust height for different screen sizes
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Confirm Password"
              variant="outlined"
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              sx={{
                marginBottom: 2,
                height: { xs: 48, sm: 56 }, // Adjust height for different screen sizes
              }}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ backgroundColor: '#4535c1', color: '#fff' }}>Signup</Button>
            {msg && <Typography color="error" variant="body2">{msg}</Typography>}
          </Box>
        )}
      </FormContainer>
    </LoginPageContainer>
  );
};

export default LoginPage;
