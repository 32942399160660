import React, { useState, useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Typography, Button, Grid, useMediaQuery, useTheme, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const FeaturedProducts = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_BACKEND_BASE_URL + '/product');
        const data = await response.json();

        const filteredProducts = data
          .filter(product => product.productType === 'external')
          .map(product => ({
            _id: product._id,
            name: product.name,
            photo: product.photo,
            startDate: product.startDate,
            endDate: product.endDate,
            company: product.company,
            description: product.description,
            link: product.link,
          }));

        setProducts(filteredProducts);
      } catch (error) {
        console.error('Failed to fetch products', error);
      }
    };

    fetchProducts();
  }, []);

  const getSlideHeight = () => {
    if (isExtraSmallScreen) return '200px';
    if (isSmallScreen) return '250px';
    if (isMediumScreen) return '275px';
    return '300px';
  };

  const handleViewDetails = (id) => {
    navigate(`/products/${id}`);
  };

  const renderSlides = () => {
    if (products.length === 1) {
      const product = products[0];
      return (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Paper elevation={4} sx={{
              padding: 2,
              textAlign: 'left',
              height: getSlideHeight(),
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              borderRadius: 2,
              marginTop: isSmallScreen ? 0 : 2,
              background: 'linear-gradient(to right, #ffffff, #f0f0f0)',
              overflow: 'hidden',
              boxShadow: theme.shadows[4],
              '&:hover': {
                transform: 'scale(1.02)',
                transition: 'transform 0.3s ease',
              }
            }}>
              <div style={{
                position: 'absolute',
                top: 10,
                right: 20,
                backgroundColor: '#ff5722',
                color: '#fff',
                padding: '2px 5px',
                borderRadius: 5,
                fontSize: '0.8rem',
                fontWeight: 'bold',
              }}>
                {moment(product.startDate).format('MMM DD')} - {moment(product.endDate).format('MMM DD')}
              </div>
              <div style={{ marginRight: 20, flex: '0 0 50%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={product.photo} alt={product.name} style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'cover', borderRadius: 8, boxShadow: '0px 4px 8px rgba(0,0,0,0.3)' }} />
              </div>
              <div style={{ marginLeft: 20, flex: '1', position: 'relative' }}>
                <Tooltip title={product.description} arrow placement="top" disableInteractive>
                  <Typography variant="h5" sx={{
                    margin: '10px 0',
                    fontWeight: 'bold',
                    fontSize: `clamp(1rem, ${14 + (100 / product.name.length)}px, 1.5rem)`,
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2, // Limit to 2 lines
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}>
                    {product.name}
                  </Typography>
                </Tooltip>
                <Typography variant="subtitle1" sx={{ margin: '5px 0', color: '#478ccf', fontWeight: 'bold', fontSize: isSmallScreen ? '1rem' : '1.2rem' }}>
                  {product.company}
                </Typography>
                <Button variant="contained" color="primary" onClick={() => handleViewDetails(product._id)} sx={{ marginTop: '10px', backgroundColor: '#478ccf' }}>
                  View Details
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      );
    } else {
      const slides = [];

      for (let i = 0; i < products.length; i += (isMediumScreen ? 1 : 2)) {
        slides.push(
          <Grid container spacing={2} key={i}>
            {isMediumScreen ? (
              <Grid item xs={12}>
                <Paper elevation={4} sx={{
                  padding: 2,
                  textAlign: 'left',
                  height: getSlideHeight(),
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  borderRadius: 2,
                  marginTop: isSmallScreen ? 0 : 2,
                  background: 'linear-gradient(to right, #ffffff, #f0f0f0)',
                  overflow: 'hidden',
                  boxShadow: theme.shadows[4],
                  '&:hover': {
                    transform: 'scale(1.02)',
                    transition: 'transform 0.3s ease',
                  }
                }}>
                  <div style={{
                    position: 'absolute',
                    top: 10,
                    right: 20,
                    backgroundColor: '#ff5722',
                    color: '#fff',
                    padding: '2px 5px',
                    borderRadius: 5,
                    fontSize: '0.8rem',
                    fontWeight: 'bold',
                  }}>
                    {moment(products[i].startDate).format('MMM DD')} - {moment(products[i].endDate).format('MMM DD')}
                  </div>
                  <div style={{ marginRight: 20, flex: '0 0 50%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={products[i].photo} alt={products[i].name} style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'cover', borderRadius: 8, boxShadow: '0px 4px 8px rgba(0,0,0,0.3)' }} />
                  </div>
                  <div style={{ marginLeft: 20, flex: '1', position: 'relative' }}>
                    <Tooltip title={products[i].description} arrow placement="top" disableInteractive>
                      <Typography variant="h5" sx={{
                        margin: '10px 0',
                        fontWeight: 'bold',
                        fontSize: `clamp(1rem, ${14 + (100 / products[i].name.length)}px, 1.5rem)`,
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2, // Limit to 2 lines
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>
                        {products[i].name}
                      </Typography>
                    </Tooltip>
                    <Typography variant="subtitle1" sx={{ margin: '5px 0', color: '#478ccf', fontWeight: 'bold', fontSize: isSmallScreen ? '1rem' : '1.2rem' }}>
                      {products[i].company}
                    </Typography>
                    <Button variant="contained" color="primary" onClick={() => handleViewDetails(products[i]._id)} sx={{ marginTop: '10px', backgroundColor: '#478ccf' }}>
                      View Details
                    </Button>
                  </div>
                </Paper>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sm={6} key={i}>
                  <Paper elevation={4} sx={{
                    padding: 2,
                    textAlign: 'left',
                    height: getSlideHeight(),
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    borderRadius: 2,
                    marginTop: isSmallScreen ? 0 : 2,
                    background: 'linear-gradient(to right, #ffffff, #f0f0f0)',
                    overflow: 'hidden',
                    boxShadow: theme.shadows[4],
                    '&:hover': {
                      transform: 'scale(1.02)',
                      transition: 'transform 0.3s ease',
                    }
                  }}>
                    <div style={{
                      position: 'absolute',
                      top: 10,
                      right: 20,
                      backgroundColor: '#ff5722',
                      color: '#fff',
                      padding: '2px 5px',
                      borderRadius: 5,
                      fontSize: '0.8rem',
                      fontWeight: 'bold',
                    }}>
                      {moment(products[i].startDate).format('MMM DD')} - {moment(products[i].endDate).format('MMM DD')}
                    </div>
                    <div style={{ marginRight: 20, flex: '0 0 50%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <img src={products[i].photo} alt={products[i].name} style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'cover', borderRadius: 8, boxShadow: '0px 4px 8px rgba(0,0,0,0.3)' }} />
                    </div>
                    <div style={{ marginLeft: 20, flex: '1', position: 'relative' }}>
                      <Tooltip title={products[i].description} arrow placement="top" disableInteractive>
                        <Typography variant="h5" sx={{
                          margin: '10px 0',
                          fontWeight: 'bold',
                          fontSize: `clamp(1rem, ${14 + (100 / products[i].name.length)}px, 1.5rem)`,
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2, // Limit to 2 lines
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}>
                          {products[i].name}
                        </Typography>
                      </Tooltip>
                      <Typography variant="subtitle1" sx={{ margin: '5px 0', color: '#478ccf', fontWeight: 'bold', fontSize: isSmallScreen ? '1rem' : '1.2rem' }}>
                        {products[i].company}
                      </Typography>
                      <Button variant="contained" color="primary" onClick={() => handleViewDetails(products[i]._id)} sx={{ marginTop: '10px', backgroundColor: '#478ccf' }}>
                        View Details
                      </Button>
                    </div>
                  </Paper>
                </Grid>
                {i + 1 < products.length && (
                  <Grid item xs={12} sm={6} key={i + 1}>
                    <Paper elevation={4} sx={{
                      padding: 2,
                      textAlign: 'left',
                      height: getSlideHeight(),
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                      borderRadius: 2,
                      marginTop: isSmallScreen ? 0 : 2,
                      background: 'linear-gradient(to right, #ffffff, #f0f0f0)',
                      overflow: 'hidden',
                      boxShadow: theme.shadows[4],
                      '&:hover': {
                        transform: 'scale(1.02)',
                        transition: 'transform 0.3s ease',
                      }
                    }}>
                      <div style={{
                        position: 'absolute',
                        top: 10,
                        right: 20,
                        backgroundColor: '#ff5722',
                        color: '#fff',
                        padding: '2px 5px',
                        borderRadius: 5,
                        fontSize: '0.8rem',
                        fontWeight: 'bold',
                      }}>
                        {moment(products[i + 1].startDate).format('MMM DD')} - {moment(products[i + 1].endDate).format('MMM DD')}
                      </div>
                      <div style={{ marginRight: 20, flex: '0 0 50%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={products[i + 1].photo} alt={products[i + 1].name} style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'cover', borderRadius: 8, boxShadow: '0px 4px 8px rgba(0,0,0,0.3)' }} />
                      </div>
                      <div style={{ marginLeft: 20, flex: '1', position: 'relative' }}>
                        <Tooltip title={products[i + 1].description} arrow placement="top" disableInteractive>
                          <Typography variant="h5" sx={{
                            margin: '10px 0',
                            fontWeight: 'bold',
                            fontSize: `clamp(1rem, ${14 + (100 / products[i + 1].name.length)}px, 1.5rem)`,
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2, // Limit to 2 lines
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}>
                            {products[i + 1].name}
                          </Typography>
                        </Tooltip>
                        <Typography variant="subtitle1" sx={{ margin: '5px 0', color: '#478ccf', fontWeight: 'bold', fontSize: isSmallScreen ? '1rem' : '1.2rem' }}>
                          {products[i + 1].company}
                        </Typography>
                        <Button variant="contained" color="primary" onClick={() => handleViewDetails(products[i + 1]._id)} sx={{ marginTop: '10px', backgroundColor: '#478ccf' }}>
                          View Details
                        </Button>
                      </div>
                    </Paper>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        );
      }

      return slides;
    }
  };

  return (
    <div>
      <Typography variant="h4" align="center" sx={{ marginBottom: 3, fontWeight: 'bold' }}>
        Featured Products
      </Typography>
      <Carousel
        navButtonsAlwaysVisible={true}
        autoPlay={true}
        cycleNavigation={true}
        animation="slide"
        interval={5000}
        indicators={true}
        sx={{ overflow: 'hidden' }}
      >
        {renderSlides()}
      </Carousel>
    </div>
  );
};

export default FeaturedProducts;
