import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Input, InputLabel, FormControl, Alert } from '@mui/material';

const AddBlog = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    photo: null,
  });
  const [message, setMessage] = useState(null); // State for handling messages

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(null); // Clear previous messages
  
    try {
      const formDataObj = new FormData();
      formDataObj.append('title', formData.title);
      formDataObj.append('description', formData.description);
      if (formData.photo) {
        formDataObj.append('photo', formData.photo);
      }

      console.log(formDataObj.get("title"))
      console.log(formDataObj.get("description"))
      console.log(formDataObj.get("photo"))

      const response = await fetch(process.env.REACT_APP_BACKEND_BASE_URL + '/blog', {
        method: 'POST',
        headers: {
          'x-auth-token': sessionStorage.getItem('admin_token') || '', // Include the token here
        },
        body: formDataObj,
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(`Failed to add blog: ${data.msg}`);
      }

      setMessage({ text: 'Blog added successfully!', type: 'success' });
      console.log('Blog added successfully:');

      // Clear form data
      setFormData({
        title: '',
        description: '',
        photo: null,
      });
  
    } catch (error) {
      setMessage({ text: `Error: ${error.message}`, type: 'error' });
      console.error('Error:', error);
    }
  };
  
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      encType="multipart/form-data" // Ensure form supports file uploads
      sx={{
        width: '100%',
        maxWidth: '600px',
        mx: 'auto',
        p: 3,
        border: '1px solid #478ccf',
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        sx={{ textAlign: 'center', mb: 3, color: '#478ccf' }}
      >
        Add Blog
      </Typography>
      {message && (
        <Alert
          severity={message.type === 'success' ? 'success' : 'error'}
          sx={{ mb: 2 }}
        >
          {message.text}
        </Alert>
      )}
      <TextField
        label="Title"
        name="title"
        variant="outlined"
        fullWidth
        required
        value={formData.title}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Description"
        name="description"
        variant="outlined"
        fullWidth
        required
        multiline
        rows={4}
        value={formData.description}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel shrink htmlFor="photo-upload">
          Upload Photo
        </InputLabel>
        <Input
          id="photo-upload"
          name="photo"
          type="file"
          onChange={handleChange}
          sx={{
            '&:hover': { backgroundColor: '#f1f1f1' },
            padding: '10px',
            border: '1px solid #478ccf',
            borderRadius: 2,
            mt: 1,
          }}
        />
      </FormControl>
      <Box sx={{ textAlign: 'center', mt: 3 }}>
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: '#478ccf',
            '&:hover': { backgroundColor: '#35699f' },
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default AddBlog;
