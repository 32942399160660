import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('user_token');
    setIsLoggedIn(!!token);
  }, [location]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('user_token');
    setIsLoggedIn(false);
  };

  const handleCartClick = () => {
    navigate('/cart');
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = () => {
    if (searchTerm.trim() !== '') {
      navigate(`/search/${encodeURIComponent(searchTerm)}`);
    }
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#478ccf' }}>
      <Toolbar>
        <Hidden mdUp>
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          <Hidden mdDown>
            <Typography
              variant="h6"
              sx={{
                color: 'white',
                fontWeight: 'bold',
                fontSize: '1.5rem',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/')}
            >
              PluckMart.com
            </Typography>
          </Hidden>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Hidden mdDown>
            <Button color="inherit">
              <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>Home</Link>
            </Button>
            <Button color="inherit">
              <Link to="/shop" style={{ color: 'white', textDecoration: 'none' }}>Shop</Link>
            </Button>
            <Button color="inherit">
              <Link to="/blog" style={{ color: 'white', textDecoration: 'none' }}>Blog</Link>
            </Button>
            <Button color="inherit">
              <Link to="/contact" style={{ color: 'white', textDecoration: 'none' }}>Contact</Link>
            </Button>
          </Hidden>
          <TextField
            placeholder="Search products..."
            variant="outlined"
            size="small"
            sx={{ ml: 1, width: 'auto', backgroundColor: 'white', borderRadius: 1 }}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <Button
            variant="contained"
            sx={{ backgroundColor: '#4535c1', color: 'white', ml: 1 }}
            onClick={handleSearchSubmit}
          >
            <SearchIcon />
          </Button>
          <Button 
            variant="contained" 
            sx={{ backgroundColor: '#f50057', color: 'white', width: 40, height: 40, ml: 1, borderRadius: '50%', minWidth: 0 }}
            onClick={handleCartClick}
          >
            <ShoppingCartIcon />
          </Button>
          <Hidden smDown>
            {isLoggedIn ? (
              <Button variant="contained" sx={{ backgroundColor: '#4535c1', color: 'white', ml: 2 }} onClick={handleLogout}>
                <LogoutIcon sx={{ mr: 1 }} /> Logout
              </Button>
            ) : (
              <Button variant="contained" sx={{ backgroundColor: '#4535c1', color: 'white', ml: 2 }}>
                <Link to="/login" style={{ color: 'white', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                  <AccountCircleIcon sx={{ mr: 1 }} /> Login
                </Link>
              </Button>
            )}
          </Hidden>
        </Box>
      </Toolbar>
      <Hidden mdUp>
        <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
          <Box sx={{ width: 250 }} onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
            <AppBar position="static" sx={{ backgroundColor: '#478ccf' }}>
              <Toolbar>
                <Typography variant="h6" sx={{ flexGrow: 1, color: 'white' }}>
                  <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>PluckMart.com</Link>
                </Typography>
                <IconButton onClick={handleDrawerToggle} sx={{ color: 'white' }}>
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Divider />
            <List>
              <ListItem button component={Link} to="/">
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem button component={Link} to="/shop">
                <ListItemText primary="Shop" />
              </ListItem>
              <ListItem button component={Link} to="/blog">
                <ListItemText primary="Blog" />
              </ListItem>
              <ListItem button component={Link} to="/contact">
                <ListItemText primary="Contact" />
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem>
                {isLoggedIn ? (
                  <Button variant="contained" sx={{ backgroundColor: '#4535c1', color: 'white', width: '100%' }} onClick={handleLogout}>
                    Logout
                  </Button>
                ) : (
                  <Button variant="contained" sx={{ backgroundColor: '#4535c1', color: 'white', width: '100%' }}>
                    <Link to="/login" style={{ color: 'white', textDecoration: 'none' }}>Login</Link>
                  </Button>
                )}
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </Hidden>
    </AppBar>
  );
};

export default Header;
