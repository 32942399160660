import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useCart } from "../hooks/CartContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(
  "pk_test_51PhRXiFPK87qt0dlEEiKecJVxN1bL1cydXhEtFTy6bp3ylF8XeluxwH5MGWme5ur2a4DWK1YrOeIaHFT8hGoUJ51007Ae8dPtM"
);

const PaymentPage = () => {
  const [clientSecret, setClientSecret] = useState("");
  const { cartItems } = useCart();
  const selectedItems = cartItems.filter((item) => item.selected);
  const total = selectedItems.reduce(
    (sum, item) => sum + item.price * item.quantity,
    0
  );

  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        const { data } = await axios.post(process.env.REACT_APP_BACKEND_BASE_URL+"/payment", {
          items: selectedItems,
          total,
        });
        setClientSecret(data.clientSecret);
      } catch (error) {
        console.error("Error creating payment intent:", error);
      }
    };

    createPaymentIntent();
  }, [selectedItems, total]);

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#f5f5f5",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Paper sx={{ padding: 3, borderRadius: 2, maxWidth: 600, width: "100%" }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ textAlign: "center", marginBottom: 4 }}
        >
          Payment
        </Typography>
        {clientSecret ? (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm selectedItems={selectedItems} total={total} />
          </Elements>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
            <Typography sx={{ marginLeft: 2 }}>
              Loading payment details...
            </Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

const CheckoutForm = ({ selectedItems, total }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { deliveryInfo } = useCart();
  const [paymentError, setPaymentError] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const token = sessionStorage.getItem("user_token");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin + "/thank-you",
      },
      redirect: "if_required",
    });

    if (error) {
      setPaymentError(error.message);
    } else if (paymentIntent.status === "succeeded") {
      setPaymentSuccess(true);
      await axios.post(
        process.env.REACT_APP_BACKEND_BASE_URL+"/save-order",
        {
          cartItems: selectedItems,
          deliveryInfo,
          billingInfo: { total },
          paymentIntentId: paymentIntent.id,
        },
        {
          headers: {
            "x-auth-token": token, // Include the token in the headers
          },
        }
      );
      navigate("/thank-you");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{
          marginTop: 2,
          backgroundColor: "#478ccf",
          color: "white",
          "&:hover": { backgroundColor: "#357ab8" },
        }}
        disabled={!stripe}
      >
        Pay
      </Button>
      {paymentError && <Typography color="error">{paymentError}</Typography>}
      {paymentSuccess && (
        <Typography color="primary">Payment successful!</Typography>
      )}
    </form>
  );
};

export default PaymentPage;
