import React, { useState } from "react";
import { Box, Drawer, List, ListItem, ListItemText, IconButton, Hidden, AppBar, Toolbar, Typography, Divider } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from '@mui/icons-material/Logout';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import BusinessIcon from '@mui/icons-material/Business'; // Icon for Companies
import CreateIcon from '@mui/icons-material/Create'; // Icon for Add Blog
import DescriptionIcon from '@mui/icons-material/Description'; // Icon for Blogs
import { useNavigate } from 'react-router-dom';
import Products from "./Products";
import AddProducts from "./AddProducts";
import AddCompany from "./AddCompany";
import Companies from "./Companies";
import AddBlog from "./AddBlog"; // New component
import Blogs from "./Blogs"; // New component

const AdminDashboard = () => {
  const [selectedOption, setSelectedOption] = useState("Add Products");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const options = [
    { text: "Add Products", icon: <AddShoppingCartIcon /> },
    { text: "Products", icon: <ShoppingCartIcon /> },
    { text: "Add Company", icon: <AddShoppingCartIcon /> },
    { text: "Companies", icon: <BusinessIcon /> },
    { text: "Add Blog", icon: <CreateIcon /> }, // New option
    { text: "Blogs", icon: <DescriptionIcon /> }, // New option
  ];

  const renderComponent = () => {
    switch (selectedOption) {
      case "Add Products":
        return <AddProducts />;
      case "Products":
        return <Products />;
      case "Add Company":
        return <AddCompany />;
      case "Companies":
        return <Companies />;
      case "Add Blog":
        return <AddBlog />; // New case
      case "Blogs":
        return <Blogs />; // New case
      default:
        return <Products />;
    }
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setDrawerOpen(false);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('admin_token');
    navigate('/');
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      {/* Drawer for small screens */}
      <Hidden mdUp>
        <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
          <Box sx={{ width: 250 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1.5 }}>
              <Typography variant="h6">Dashboard</Typography>
              <IconButton onClick={handleDrawerToggle}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
            <List>
              {options.map(({ text, icon }) => (
                <ListItem button key={text} onClick={() => handleOptionClick(text)}>
                  {React.cloneElement(icon, { sx: { color: 'black' } })}
                  <ListItemText primary={text} sx={{ ml: 2 }} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
        <AppBar position="fixed" sx={{ backgroundColor: '#478ccf' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Admin Dashboard
            </Typography>
            <IconButton color="inherit" aria-label="logout" onClick={handleLogout}>
              <LogoutIcon />
              <Typography variant="button" sx={{ ml: 1 }}>
                Logout
              </Typography>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
          {renderComponent()}
        </Box>
      </Hidden>
      
      {/* Sidebar and content for medium and large screens */}
      <Hidden mdDown>
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Box component="nav" sx={{ width: '20%', flexShrink: 0 }}>
            <Drawer
              variant="permanent"
              open
              sx={{
                width: '20%',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: '20%',
                  boxSizing: 'border-box',
                  backgroundColor: '#478ccf', // Sidebar background color
                },
              }}
            >
              <List>
                <ListItem
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 2,
                    backgroundColor: 'white',
                    width: '90%',
                    mx: 'auto',
                    mb: 2,
                    boxShadow: 2,
                    p: 1,
                    flexWrap: 'wrap', // Allow wrapping
                    textAlign: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row', // Align items in a row
                      alignItems: 'center', // Center items vertically
                      width: '100%',
                      borderRadius: 2,
                      backgroundColor: 'white',
                      gap: 2, // Add space between heading and button
                      flexWrap: 'wrap', // Allow wrapping when space is not sufficient
                      justifyContent: 'center', // Center items horizontally
                    }}
                  >
                    <Typography variant="h6" sx={{ flexShrink: 0 }}>
                      Dashboard
                    </Typography>
                    <IconButton
                      sx={{
                        backgroundColor: '#478ccf',
                        color: 'white',
                        borderRadius: 2,
                        '&:hover': {
                          backgroundColor: '#7aa9e1',
                        },
                      }}
                      aria-label="logout"
                      onClick={handleLogout}
                    >
                      <LogoutIcon />
                      <Typography variant="button" sx={{ ml: 1 }}>
                        Logout
                      </Typography>
                    </IconButton>
                  </Box>
                </ListItem>
                <Divider sx={{ borderColor: 'white' }} />
                {options.map(({ text, icon }) => (
                  <React.Fragment key={text}>
                    <ListItem button onClick={() => handleOptionClick(text)}>
                      {React.cloneElement(icon, { sx: { color: 'white' } })}
                      <ListItemText primary={text} sx={{ ml: 2, color: 'white' }} />
                    </ListItem>
                    <Divider sx={{ borderColor: 'white' }} />
                  </React.Fragment>
                ))}
              </List>
            </Drawer>
          </Box>
          <Box component="main" sx={{ flexGrow: 1, p: 3, width: '80%' }}>
            {renderComponent()}
          </Box>
        </Box>
      </Hidden>
    </Box>
  );
};

export default AdminDashboard;
