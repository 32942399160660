// src/components/BillDownloader.jsx
import React from 'react';

const BillDownloader = ({ cartItems, deliveryInfo, billingInfo }) => {
  const handleDownload = () => {
    // Logic to download the bill as PDF
  };

  return (
    <div>
      <h2>Order Summary</h2>
      <div>
        <h3>Delivery Information</h3>
        <p>Name: {deliveryInfo.name}</p>
        <p>Address: {deliveryInfo.address}</p>
        <p>Phone: {deliveryInfo.phone}</p>
      </div>
      <div>
        <h3>Cart Items</h3>
        <ul>
          {cartItems.map((item) => (
            <li key={item.id}>
              {item.name} - Quantity: {item.quantity} - Price: {item.price}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <h3>Billing Information</h3>
        <p>Total: {billingInfo.total}</p>
      </div>
      <button onClick={handleDownload}>Download Bill</button>
    </div>
  );
};

export default BillDownloader;
