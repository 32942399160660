import React from 'react';
import FeaturedProducts from '../components/FeaturedProducts';
import Categories from '../components/Categories';
import { Container } from '@mui/material'; // Import Container from Material-UI

const HomePage = () => {
  return (
    <Container maxWidth="lg" sx={{ margin: '1', textAlign: 'center', paddingTop: '20px' }}>
      <FeaturedProducts />
      <Categories />
    </Container>
  );
}

export default HomePage;
