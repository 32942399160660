import React from 'react';
import { Box, Typography, TextField, Button, Container } from '@mui/material';

const ContactUsPage = () => {
  return (
    <Container maxWidth="sm" sx={{ my: 4 }}> {/* Added top and bottom margin */}
      <Box sx={{ padding: 4, backgroundColor: '#ffffff', borderRadius: 2, boxShadow: 3 }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#333', textAlign: 'center' }}>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#666', textAlign: 'center' }}>
          We would love to hear from you! Please fill out the form below and we will get in touch with you shortly.
        </Typography>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            required
            sx={{ backgroundColor: '#f5f5f5' }}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            type="email"
            required
            sx={{ backgroundColor: '#f5f5f5' }}
          />
          <TextField
            label="Message"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            required
            sx={{ backgroundColor: '#f5f5f5' }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ marginTop: 2, backgroundColor: '#478ccf', '&:hover': { backgroundColor: '#357ab8' } }}
          >
            Send Message
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ContactUsPage;
